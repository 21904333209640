.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
input[type=text]{
  width:100%;
  border:2px solid #000 !important;
  border-radius:4px;
  margin:8px 0;
  /* outline:none; */
  padding:8px;
  box-sizing:border-box;
  transition:.3s;
}

input[type=text]:focus{
  border-color:dodgerBlue;
  box-shadow:0 0 8px 0 dodgerBlue;
}

.invoice-container,
.crypto-container,
.payment-container {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 30px;
}
.dropdown-container{
  margin-top: 2px;
  margin-bottom: 20px;
}
.invoice-container input {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.invoice-container button {
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
}

.crypto-container select {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
.step4-button-container{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.btn-mid-container{
  display: flex;
  justify-content:center;
  align-items: center;
}
.payment-container p {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.payment-container input {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.payment-container .qr-code {
  display: block;
  margin: 20px auto;
  max-width: 150px;
  height: auto;
}

.payment-container button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
}
.crypto-container button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .invoice-container,
  .crypto-container,
  .payment-container {
    max-width: 90%;
  }
}

.invoice-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
}

.logo-container {
display: flex;
width: 250px;
height: 250px;
padding-bottom: 25px;
}
.walletaddress{
  display: flex;
  gap: 10px;
  flex-direction: row;
  background-color: #f2f2f2;
  padding: 12px 10px 8px 15px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.span-text{
  color: grey;
}
.cursor{
  cursor: pointer;
}
.span-text-warning{
  color: orange;

}
.invoice-sections-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.invoice-to-container,
.pay-to-container {
  width: 45%;
}

.invoice-to-container h3,
.pay-to-container h3 {
  margin-top: 0;
}

.invoice-to-container p,
.pay-to-container p {
  margin: 0;
}

.invoice-items-container {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #eee;
}

.grand-total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grand-total-container h3 {
  margin: 0;
}

.grand-total-container p {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
}